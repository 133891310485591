<template>
  <div>
    <div class="d-flex justify-content-between py-5">
      <div>
        <p class="mb-0 description">Reservar un mensajero por horas</p>
        <p class="mb-0 rules">
          Reserva con un máximo de 12 horas como límite por día.
        </p>
      </div>
      <div class="d-flex align-self-end">
        <z-button variant="secondary" class="mr-3" @click="$emit('cancel')">
          Cancelar
        </z-button>
        <z-button
          v-b-modal="'hour-rent-confirmation-modal'"
          :disabled="!enableReservation"
          @click="$emit('reserve')"
        >
          Reservar
        </z-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceRentCalendarHeader",

  props: {
    enableReservation: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.rules {
  font-size: 12px;
  color: $dim-gray;
}
</style>
