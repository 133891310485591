<template>
  <div>
    <div v-if="!isMobile()">
      <time-zone-message />
      <service-rent-calendar
        :events="events"
        :modal-open="requestPanelProps.show"
        @calendar-ready="handleCalendarReady($event)"
        @event-drop="handleEventDrop"
        @event-render="handleEventRender"
        @event-resize="handleEventResize"
        @event-selected="handleEventSelected"
        @reserve="showConfirmationModal = true"
        @cancel="$emit('cancel')"
      />
      <service-rent-request-panel
        v-if="requestPanelProps.show"
        :show="requestPanelProps.show"
        :left="requestPanelProps.left"
        :top="requestPanelProps.top"
        :event="selectedEvent"
        :reservation-address="reservationAddress"
        :reservation-user="reservationUser"
        :reservation-vehicle-type="reservationVehicleType"
        @cancel="handleRequestPanelCancel($event)"
        @save="handleRequestPanelSave($event)"
        @clickaway="requestPanelProps.show = false"
      />
      <reservation-confirmation-modal
        v-model="showConfirmationModal"
        is-admin
        :services="eventsPendingToReserve"
        :address="reservationAddress"
        :user="reservationUser"
        :vehicle-type="reservationVehicleType"
        :reserve-func="handleReserve"
        :estimate-func="handleEstimation"
      />
    </div>
    <div
      v-else
      class="p-5 h-100 d-flex justify-content-center align-items-center"
    >
      <div class="text-center">
        <div class="h2">Acceso Restringido</div>
        <div class="mt-3">
          Para mejorar la experiencia, esta funcionalidad solo está disponible
          en la versión de Escritorio
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Clients from "@/services/clients";
import ServiceRentCalendar from "./ServiceRentCalendar";
import ServiceRentRequestPanel from "./ServiceRentRequestPanel";
import TimeZoneMessage from "./ServiceRentTimeZoneMessage";
import moment from "moment";
import { truncate } from "@/utils/filters";
import isEqual from "lodash/isEqual";
import UserStatus from "@/constants/clients/status";
import ReservationDrivers from "@/constants/reservations/drivers";
import ReservationSchedule from "@/constants/reservations/schedule";
import VehicleTypes from "@/constants/vehicles/type";
import ScreensMixin from "@/mixins/screen";
import ReservationConfirmationModal from "@zubut/common/src/components/ReservationConfirmationModal";
import Reservations from "@/services/reservations";
import notifyMixin from "@/mixins/notify";
import _cloneDeep from "lodash/cloneDeep";

export default {
  name: "ServiceRentCreation",

  components: {
    ReservationConfirmationModal,
    ServiceRentCalendar,
    ServiceRentRequestPanel,
    TimeZoneMessage
  },

  mixins: [notifyMixin("notifications"), ScreensMixin],

  data() {
    return {
      eventId: 1,
      events: [],
      requestPanelProps: {
        show: false,
        left: 0,
        top: 0
      },
      selectedEvent: null,
      reservationUser: null,
      reservationAddress: null,
      users: [],
      showConfirmationModal: false,
      reservationVehicleType: VehicleTypes.NUM_MOTORCYCLE
    };
  },

  computed: {
    eventsPendingToReserve() {
      return this.events.filter(ev => ev.saved && !ev.reserved);
    }
  },

  beforeMount() {
    this.getUsers();
  },

  methods: {
    handleEstimation(data) {
      return Reservations.estimation(data);
    },

    createNewEvent(start, end) {
      let address = null;
      let saved = false;
      if (this.reservationAddress) {
        address = _cloneDeep(this.reservationAddress);
        saved = true;
      } else {
        this.removeNotSavedEvents();
      }
      return {
        id: this.eventId++,
        start: start.format(),
        end: end.format(),
        address,
        drivers: ReservationDrivers.MIN_DRIVERS,
        saved,
        reserved: false,
        vehicleType: this.reservationVehicleType
      };
    },

    customEventContent({ title, start, end, reserved, drivers }) {
      return `
        <div ${reserved ? 'class="reserved"' : ""}>
          <div>${reserved ? "Reservado" : ""}</div>
          <div class="fc-title">${truncate(title, 35)}</div>
          <div class="fc-time">${start.format("h:mm a")} - ${end.format(
        "h:mm a"
      )}</div>
        </div>
        <div class="fc-drivers">
          <img src="${require("@/assets/img/ic_helmet.png")}"> ${drivers}
        </div>
    `;
    },

    formatEvent(event) {
      let parsedEvent = null;
      if (event.extendedProps) {
        parsedEvent = {
          end: moment(event.end._i || event.end).format(),
          id: parseInt(event.id, 10),
          start: moment(event.start._i || event.start).format(),
          title: _cloneDeep(event.extendedProps.address),
          cost: event.extendedProps.cost,
          address: _cloneDeep(event.extendedProps.address),
          drivers: event.extendedProps.drivers,
          saved: event.extendedProps.saved
        };
      } else {
        parsedEvent = {
          end: moment(event.end._i || event.end).format(),
          id: parseInt(event.id, 10),
          start: moment(event.start._i || event.start).format(),
          title: event.address,
          address: event.address,
          cost: event.cost,
          drivers: event.drivers,
          saved: event.saved,
          reserved: event.reserved
        };
      }
      return parsedEvent;
    },

    handleCalendarReady(calendar) {
      this.calendar = calendar;
      this.$nextTick(() => {
        this.calendar.rerenderEvents();
      });
    },

    handleEventRender(event, element) {
      const start = moment(event.start);
      event.start = start;
      const end = moment(event.end);
      event.title = event.address
        ? event.address.company || event.address.name
        : "Seleccione un origen";

      const minuteDifference = end.diff(start, "minute");
      const eventIndex = this.events.findIndex(
        ev => ev.id === parseInt(event.id)
      );
      if (minuteDifference < ReservationSchedule.MIN_MINUTES) {
        event.end = moment(start).add(ReservationSchedule.MIN_HOURS, "hours");
        if (eventIndex === -1) {
          const newEvent = this.createNewEvent(start, event.end);
          this.updateEvents(newEvent);
          return false;
        }
        return false;
      }
      if (event.reserved) {
        element.addClass("reserved");
      }
      return true;
    },

    handleEventResize(event, delta, revertFunc) {
      const eventIndex = this.events.findIndex(
        ev => ev.id === parseInt(event.id)
      );
      if (eventIndex !== -1) {
        const end = moment(event.end);
        const minutesDifference = moment(event.end).diff(
          moment(event.start),
          "minutes"
        );

        if (
          event.extendedProps.reserved ||
          minutesDifference < ReservationSchedule.MIN_MINUTES ||
          minutesDifference > ReservationSchedule.MAX_MINUTES ||
          end.hours() > ReservationSchedule.WEEK_TO
        ) {
          revertFunc();
          this.notify({
            text: `El bloque debe durar entre ${ReservationSchedule.MIN_HOURS} y ${ReservationSchedule.MAX_HOURS} horas`,
            title: "Bloque invalid",
            type: "warn"
          });
        } else {
          this.updateEvents(this.formatEvent(event));
        }
      }
    },

    handleEventDrop(event, delta, revert) {
      if (event.extendedProps.reserved) {
        revert();
      } else {
        this.updateEvents(this.formatEvent(event));
      }
      this.requestPanelProps.show = false;
    },

    handleEventSelected(event, element) {
      this.selectedEvent = this.formatEvent(event);
      if (!event.extendedProps.reserved) {
        this.selectedEventId = parseInt(event.id, 10);
        this.positionRequestPanel(element);
        this.requestPanelProps.show = false;
        this.$nextTick(() => {
          this.requestPanelProps.show = true;
        });
      }
    },

    handleRequestPanelCancel(isReservedEvent) {
      this.requestPanelProps.show = false;
      if (!isReservedEvent) {
        this.events = this.events.filter(
          ev => ev.id !== parseInt(this.selectedEvent.id, 10)
        );
        if (!this.eventsPendingToReserve.length > 0) {
          this.reservationAddress = null;
        }
      }
    },

    handleRequestPanelSave({ data, closeRequestPanel }) {
      let event = this.formatEvent(this.selectedEvent);
      event = { ...event, ..._cloneDeep(data) };
      event.saved = true;
      if (closeRequestPanel) {
        this.requestPanelProps.show = false;
      }
      this.reservationUser = this.users.find(el => el.id === event.clientId);
      this.reservationVehicleType = data.vehicleType;
      this.updateEvents(event);
    },

    async handleReserve(requestData) {
      return Reservations.adminCreate(requestData)
        .then(() => {
          this.notify({
            text: "La reservación fue creada con éxito",
            title: "Operación exitosa",
            type: "success"
          });
          this.$emit("create");
        })
        .catch(err => {
          this.$captureError(err);
          this.notify({
            type: "error",
            title: err.message
          });
        })
        .finally(() => {
          this.showConfirmationModal = false;
        });
    },

    getOffset(el) {
      const box = el.getBoundingClientRect();
      const requestPanelWidth = 412;
      const verticalOffset = Math.max(
        window.pageYOffset,
        document.body.scrollTop
      );
      const position = {
        top: box.top + verticalOffset - document.documentElement.clientTop - 10,
        left:
          box.left +
          window.pageXOffset -
          document.documentElement.clientLeft -
          70 +
          box.width
      };
      const isOffScreenRight =
        position.left > window.innerWidth - requestPanelWidth - 100;

      if (isOffScreenRight) {
        position.left = position.left - box.width - requestPanelWidth - 10;
      }

      return position;
    },

    positionRequestPanel(element) {
      const position = this.getOffset(element);
      this.requestPanelProps.left = position.left;
      this.requestPanelProps.top = position.top;
    },

    updateEvents(event) {
      const eventIndex = this.events.findIndex(
        ev => ev.id === parseInt(event.id, 10)
      );
      const eventsOverlaped = this.eventOverlaps(event);

      if (eventsOverlaped.length > 0) {
        let mergedEvent = _cloneDeep(event);
        eventsOverlaped.forEach(ev => {
          this.events = this.events.filter(
            ev => ev.id !== parseInt(event.id, 10)
          );
          mergedEvent = this.mergeEvents(ev, mergedEvent);
        });
        this.updateEvent(mergedEvent);
      } else if (eventIndex === -1) {
        this.events.push(event);
        if (event.address && this.reservationAddress === null) {
          this.setReservationAddress(event);
        }
      } else if (isEqual(event.address, this.reservationAddress)) {
        this.updateEvent(event);
      } else {
        this.updateEvent(event);
        this.setReservationAddress(event);
        this.updateNonReservedEventsAddress();
      }
    },

    eventOverlaps(_ev) {
      const eventStart = moment(_ev.start);
      const eventEnd = moment(_ev.end);
      const eventsBetween = this.events.filter(ev => {
        if (!ev.reserved && ev.saved && ev.id !== _ev.id) {
          const evStart = moment(ev.start);
          const evEnd = moment(ev.end);
          const startIsSame = evStart.isSame(eventStart);
          const endIsSame = evEnd.isSame(eventEnd);
          let newDrivers = 0;
          if (_ev.drivers) {
            newDrivers += _ev.drivers;
          }
          if (ev.drivers) {
            newDrivers += ev.drivers;
          }
          const validDriverCount = newDrivers <= ReservationDrivers.MAX_DRIVERS;
          if (startIsSame && endIsSame && validDriverCount) {
            return true;
          }
        }
        return false;
      });
      return eventsBetween || false;
    },

    mergeEvents(evA, evB) {
      const evAStart = moment(evA.start).format();
      const evAEnd = moment(evA.end).format();
      let newDrivers = 0;
      if (evA.drivers) {
        newDrivers += evA.drivers;
      }
      if (evB.drivers) {
        newDrivers += evB.drivers;
      }
      return { ...evA, start: evAStart, end: evAEnd, drivers: newDrivers };
    },

    updateEvent(event) {
      this.events = this.events.map(ev =>
        ev.id && ev.id === event.id ? _cloneDeep(event) : _cloneDeep(ev)
      );
    },

    updateNonReservedEventsAddress() {
      this.events = this.events.map(ev => {
        if (!ev.reserved) {
          return { ...ev, address: _cloneDeep(this.reservationAddress) };
        }
        return ev;
      });
    },

    setReservationAddress(event) {
      this.reservationAddress = {
        ...event.address,
        id: null
      };
    },

    removeNotSavedEvents() {
      this.events = _cloneDeep(this.events.filter(ev => ev.saved));
    },

    getUsers() {
      Clients.find({
        filter: {
          include: ["addresses", "creditCards", "wallet"],
          where: {
            and: [{ status: UserStatus.NUM_ACTIVE }]
          }
        }
      })
        .then(users => {
          this.users = users.map(user => {
            if (user.isConsolidatedUser) {
              user.cardId = null;
            } else if (
              user.creditCards.length &&
              user.creditCards.find(c => c.default)
            ) {
              user.cardId = user.creditCards.find(c => c.default).id;
            } else if (user.wallet) {
              user.walletId = user.wallet.id;
            }
            delete user.creditCards;
            return user;
          });
        })
        .catch(err => {
          this.$captureError(err);
        });
    }
  }
};
</script>
