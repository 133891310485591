<template>
  <div
    id="hour-rent-request-panel"
    v-on-clickaway="handleClickaway"
    :style="{ top: `${top}px`, left: `${left}px` }"
    class="px-3"
  >
    <div>
      <div class="d-flex align-items-center py-3 border-bottom">
        <font-awesome-icon class="ml-1 mr-2" icon="user" size="lg" />
        <z-dropdown-clients
          v-model="selectedUser"
          search
          placeholder="Selecciona un usuario"
          class="w-100"
          @input="resetAddress"
        />
      </div>
      <b-form-group class="py-3 mb-0 auto-complete border-bottom">
        <div class="d-flex">
          <div class="ml-n1 mr-3 text-primary">
            <font-awesome-icon icon="hexagon-bullet" size="lg" />
          </div>
          <address-autocomplete
            class="form-control"
            :address="selectedAddress"
            placeholder="Dirección origen"
            select-first-on-enter
            name="address"
            type="text"
            required
            :class="[
              {
                'is-valid':
                  selectedAddress.valid === true &&
                  selectedAddress.pristine === false
              }
            ]"
            @address-change="handleAddressChange"
            @focus.native="showFavoriteAddresses = false"
          />
          <favorite-addresses
            :disabled="!hasFavoriteAddresses"
            :show.sync="showFavoriteAddresses"
            :addresses="favoriteAddresses"
            @address-selected="selectFavoriteAddress"
          />
        </div>
      </b-form-group>
    </div>
    <div class="d-flex pl-4 pb-3 pr-4 pt-3 border-bottom">
      <b-input
        v-model="name"
        v-validate="'required'"
        class="ml-2 mr-3 w-50"
        placeholder="¿Con quién vamos?"
        name="name"
        data-vv-as="nombre"
        :disabled="disableControls"
        :state="addressNameState"
      ></b-input>
      <b-input
        v-model="phone"
        v-validate="'required'"
        class="w-50"
        placeholder="Teléfono"
        data-vv-as="teléfono"
        name="phone"
        :disabled="disableControls"
        :state="addressPhoneState"
      ></b-input>
    </div>
    <div class="d-flex pl-4 pb-3 pt-3 border-bottom">
      <b-input
        v-model="info"
        v-validate="'min:0'"
        type="text"
        placeholder="Cruces, referencias, información del paquete"
        class="ml-2"
        name="info"
        data-vv-as="información"
        :disabled="disableControls"
        :state="addressInfoState"
      />
    </div>
    <div class="py-3 border-bottom d-flex">
      <font-awesome-icon
        class="ml-1 align-self-center"
        icon="clock-calendar"
        size="lg"
      />
      <div class="d-flex flex-column">
        <div class="d-flex align-items-center mb-2">
          <date-picker
            v-model="customStartDateFilter"
            wrapper-class="date-picker-wrapper mx-2"
            calendar-class="date-picker"
            :language="customDateConfig.language"
            :disabled-dates="customDateConfig.disabledDates"
            input-class="form-control bg-white w-100"
            placeholder="Fecha"
          />
          <z-dropdown
            :key="startDateKey"
            v-model="selectedStartTime"
            :options="startTimeOptions"
            :disabled="disableControls"
          />
        </div>
        <div class="d-flex align-items-center">
          <date-picker
            v-model="customEndDateFilter"
            wrapper-class="date-picker-wrapper mx-2"
            calendar-class="date-picker"
            :language="customDateConfig.language"
            :disabled-dates="customDateConfig.disabledDates"
            input-class="form-control bg-white w-100"
            placeholder="Fecha"
          />
          <z-dropdown
            :key="endDateKey"
            v-model="selectedEndTime"
            :options="endTimeOptions"
            :disabled="disableControls"
          />
        </div>
      </div>
    </div>
    <div class="py-3 border-bottom drivers-container">
      <font-awesome-icon class="ml-1 mr-2" icon="helmet" size="lg" />
      <b-input
        v-model="driverCount"
        v-validate="driverValidation"
        type="number"
        class="drivers-input d-inline"
        name="drivers"
        data-vv-as="mensajeros"
        :state="driverCountState"
        :disabled="disableControls"
        min="1"
      />
      <span class="invalid-feedback d-block text-left ml-4 pl-2">
        {{ veeErrors.first("drivers") }}
      </span>
    </div>
    <div class="border-bottom px-2">
      <delivery-methods
        class="request-panel-delivery-methods mb-0"
        :vehicle-type.sync="selectedVehicleType"
        :prices="vehicleRates"
        :car-allowed="isCarAllowed"
        :eco-allowed="isEcoAllowed"
        :motorcycle-allowed="isMotorcycleAllowed"
        not-allowed-message="Tarifa no establecida"
      />
    </div>
    <div class="py-3 d-flex justify-content-end">
      <z-button class="mr-2" variant="secondary" @click="handleCancel">
        Eliminar
      </z-button>
      <z-button :disabled="disableSaveButton" @click="handleSave(true)">
        Guardar
      </z-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import notifyMixin from "@/mixins/notify";
import validateStateMixin from "@/mixins/validateState";
import AddressAutocomplete from "@/app/components/AddressAutocomplete";
import DeliveryMethods from "@zubut/common/src/components/DeliveryMethods";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ZDropdownClients from "@/app/components/ZDropdownClients";
import FavoriteAddresses from "@/app/components/FavoriteAddresses";
import ReservationDrivers from "@/constants/reservations/drivers";
import ReservationSchedule from "@/constants/reservations/schedule";
import VehicleTypes from "@/constants/vehicles/type";
import Clients from "@/services/clients";
import DatePicker from "vuejs-datepicker";
import isEqual from "lodash/isEqual";
import { formatSendPhone } from "@zubut/common/src/utils/strings";
import { format } from "@zubut/common/src/utils/time";
import { es } from "vuejs-datepicker/dist/locale";
import { mixin as clickaway } from "vue-clickaway";
import currency from "currency.js";
import {
  startOfDay,
  toDate,
  getHours,
  getMinutes,
  set,
  parseISO,
  differenceInHours
} from "date-fns";

export default {
  name: "ServiceRentRequestPanel",

  components: {
    AddressAutocomplete,
    DatePicker,
    DeliveryMethods,
    FavoriteAddresses,
    ZDropdown,
    ZDropdownClients
  },

  mixins: [clickaway, validateStateMixin, notifyMixin("notifications")],

  props: {
    event: {
      type: Object,
      default: null
    },
    show: {
      type: Boolean,
      default: true
    },
    left: {
      type: Number,
      default: 0
    },
    top: {
      type: Number,
      default: 0
    },
    reservationUser: {
      type: Object,
      default: null
    },
    reservationAddress: {
      type: Object,
      default: null
    },
    reservationVehicleType: {
      type: Number,
      default: VehicleTypes.NUM_MOTORCYCLE
    }
  },

  data() {
    return {
      customStartDateFilter: new Date(),
      customEndDateFilter: new Date(),
      customDateConfig: {
        format: "DD/MM/YYYY",
        useCurrent: true,
        language: es
      },
      driverCount: 1,
      timeOptions: this.createTimeOptions(),
      selectedStartTime: null,
      selectedEndTime: null,
      selectedVehicleType: 0,
      selectedAddress: {
        pristine: true,
        valid: false,
        address: "",
        position: {}
      },
      vehicleRates: {
        Motorcycle: null,
        Car: null,
        Eco: null
      },
      name: "",
      phone: "",
      info: "",
      selectedUser: null,
      oldSelectedUser: null,
      driverValidation: {
        required: true,
        max_value: ReservationDrivers.MAX_DRIVERS,
        min_value: ReservationDrivers.MIN_DRIVERS
      },
      startDateKey: Date.now() + 1,
      endDateKey: Date.now(),
      hourFixed: null,
      showFavoriteAddresses: false,
      favoriteAddresses: []
    };
  },

  computed: {
    disableControls() {
      if (this.event && this.event.reserved) {
        return true;
      }
      return false;
    },

    hasValidData() {
      return this.selectedUser &&
        this.selectedAddress.valid &&
        this.isEventDurationAllowed &&
        !this.disableControls &&
        this.isFormValid
        ? true
        : false;
    },

    disableSaveButton() {
      return !this.hasValidData;
    },

    addressNameState() {
      return this.isValidField("name");
    },

    addressPhoneState() {
      return this.isValidField("phone");
    },

    addressInfoState() {
      return this.isValidField("info");
    },

    driverCountState() {
      return this.isValidField("drivers");
    },

    startTimeOptions() {
      return this.timeOptions.reduce((arr, item) => {
        const minutes = item.value % 1 === 0.5 ? 30 : 0;
        const newStartDate = set(this.customStartDateFilter, {
          hours: item.value,
          minutes: minutes,
          seconds: 0
        });
        const newDuration = differenceInHours(this.eventEndDate, newStartDate, {
          roundingMethod: "floor"
        });
        if (
          newDuration > ReservationSchedule.MAX_HOURS ||
          newDuration < ReservationSchedule.MIN_HOURS
        ) {
          return arr;
        }
        arr.push(item);
        return arr;
      }, []);
    },

    endTimeOptions() {
      return this.timeOptions.reduce((arr, item) => {
        const minutes = item.value % 1 === 0.5 ? 30 : 0;
        const newEndDate = set(this.customEndDateFilter, {
          hours: item.value,
          minutes: minutes,
          seconds: 0
        });
        const newDuration = differenceInHours(newEndDate, this.eventStartDate, {
          roundingMethod: "ceil"
        });
        if (
          newDuration > ReservationSchedule.MAX_HOURS ||
          newDuration < ReservationSchedule.MIN_HOURS
        ) {
          return arr;
        }
        arr.push(item);
        return arr;
      }, []);
    },

    hasFavoriteAddresses() {
      return this.favoriteAddresses.length > 0;
    },

    eventStartDate() {
      return set(this.customStartDateFilter, {
        hours: this.selectedStartTime,
        minutes: 0,
        seconds: 0
      });
    },

    eventEndDate() {
      return set(this.customEndDateFilter, {
        hours: this.selectedEndTime,
        minutes: 0,
        seconds: 0
      });
    },

    eventDuration() {
      if (this.selectedStartTime != null && this.selectedEndTime != null) {
        return differenceInHours(this.eventEndDate, this.eventStartDate);
      }
      return 0;
    },

    isEventDurationAllowed() {
      return (
        this.eventDuration <= ReservationSchedule.MAX_HOURS &&
        this.eventDuration >= ReservationSchedule.MIN_HOURS
      );
    },

    isCarAllowed() {
      return this.vehicleRates.Car !== null;
    },

    isMotorcycleAllowed() {
      return this.vehicleRates.Motorcycle !== null;
    },

    isEcoAllowed() {
      return this.vehicleRates.Eco !== null;
    }
  },

  watch: {
    event: {
      immediate: true,
      handler(newEvent) {
        if (newEvent) {
          const start = parseISO(newEvent.start);
          const end = parseISO(newEvent.end);
          this.customStartDateFilter = toDate(startOfDay(start));
          this.customEndDateFilter = toDate(startOfDay(end));
          const startValue = getMinutes(start)
            ? getHours(start)
            : getHours(start);
          const endValue = getMinutes(end)
            ? getHours(end) + 0.5
            : getHours(end);
          this.selectedStartTime = startValue;
          this.selectedEndTime = endValue;
          this.driverCount = newEvent.drivers || ReservationDrivers.MIN_DRIVERS;
        }
      }
    },

    selectedEndTime(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.handleSave(false, null, oldVal);
      }
    },

    selectedStartTime(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.handleSave(false, oldVal);
      }
    },

    reservationAddress: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.selectFavoriteAddress(newVal);
        }
      }
    },

    reservationUser: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.selectedUser = newVal.id || null;
          this.getRatesReservation();
        }
      }
    },

    reservationVehicleType: {
      immediate: true,
      handler(newVal) {
        this.selectedVehicleType = newVal;
      }
    },

    selectedUser: {
      handler(newVal) {
        if (newVal) {
          this.getRatesReservation();
        }
      }
    }
  },

  created() {
    if (this.reservationUser?.id) {
      this.fetchClientAddresses(this.reservationUser.id);
    }
  },

  methods: {
    fetchClientAddresses(clientId) {
      if (clientId) {
        Clients.findById({
          id: clientId,
          filter: {
            include: ["addresses"]
          }
        }).then(user => {
          this.favoriteAddresses = user.addresses;
        });
      }
    },

    createTimeOptions() {
      const res = [];
      for (let i = 0; i <= 23; i++) {
        let meridiem = i >= 12 ? "pm" : "am";
        let hour = i > 12 ? i - 12 : i;

        res.push({ value: i, text: `${hour}:00 ${meridiem}` });

        if (i !== 23) {
          res.push({ value: i + 0.5, text: `${hour}:30 ${meridiem}` });
        }
      }
      return res;
    },

    handleCancel() {
      this.$emit("cancel", this.event.reserved || false);
    },

    handleSave(closeRequestPanel = false, oldStart = null, oldEnd = null) {
      const { start, end } = this.buildDateRange();
      if (this.isValidDate()) {
        const data = {
          start: format(start, "yyyy-MM-dd'T'HH:mm:ss"),
          end: format(end, "yyyy-MM-dd'T'HH:mm:ss"),
          drivers: parseInt(this.driverCount),
          vehicleType: this.selectedVehicleType
        };
        if (this.selectedUser != null) {
          data.clientId = this.selectedUser;
        }
        if (this.selectedAddress && this.selectedAddress.valid) {
          data.address = {
            id: this.event.id,
            address: this.selectedAddress.address,
            position: this.selectedAddress.position,
            name: this.name,
            phone: formatSendPhone(this.phone),
            information: this.info,
            valid: true
          };
        }
        this.$emit("save", { data, closeRequestPanel });
      } else if (oldStart) {
        this.selectedStartTime = oldStart;
      } else if (oldEnd) {
        this.selectedEndTime = oldEnd;
      }
    },

    buildDateRange() {
      const startDay = this.customStartDateFilter;
      const endDay = this.customEndDateFilter;
      const startIsHalfHour =
        (this.selectedStartTime + "").split(".")[1] || false;
      const startMinutes = startIsHalfHour ? 30 : 0;
      const endIsHalfHour = (this.selectedEndTime + "").split(".")[1] || false;
      const endMinutes = endIsHalfHour ? 30 : 0;
      const start = set(startOfDay(startDay), {
        hours: this.selectedStartTime,
        minutes: startMinutes
      });
      const end = set(startOfDay(endDay), {
        hours: this.selectedEndTime,
        minutes: endMinutes
      });
      return { start, end };
    },

    handleAddressChange(address, complete) {
      this.selectedAddress = { ...address, complete, pristine: false };
    },

    handleClickaway() {
      this.$emit("clickaway");
    },

    isValidDate() {
      if (!this.isEventDurationAllowed) {
        this.notify({
          type: "warn",
          text: "Seleccione mínimo 4 y máximo 12 horas de reservación"
        });
        return false;
      }
      return true;
    },

    selectFavoriteAddress(address) {
      this.selectedAddress.address = address.address;
      this.selectedAddress.position = address.position;
      this.selectedAddress.valid = true;
      this.selectedAddress.pristine = false;
      this.name = address.name;
      this.phone = address.phone;
      this.info = address.info;
    },

    resetAddress() {
      // This sets the inital address if the inital user
      // is selected again
      if (
        isEqual(this.selectedUser, this.reservationUser) &&
        this.reservationAddress
      ) {
        this.selectedAddress = { ...this.reservationAddress };
        this.name = this.reservationAddress.name;
        this.phone = this.reservationAddress.phone;
        this.info = this.reservationAddress.information;
      } else if (
        this.oldSelectedUser !== null &&
        !isEqual(this.selectedUser, this.oldSelectedUser)
      ) {
        this.selectedAddress = {
          pristine: true,
          valid: false,
          address: "",
          position: {}
        };

        this.name = "";
        this.phone = "";
        this.info = "";
      }
      this.oldSelectedUser = { ...this.selectedUser };
      this.fetchClientAddresses(this.selectedUser);
    },

    getRatesReservation() {
      Clients.getRatesReservation(this.selectedUser)
        .then(res => {
          const {
            bikeHourNighttime: bikeHourMax,
            bikeHourWeekRange2: bikeHourMin,
            carHourNighttime: carHourMax,
            carHourWeekRange2: carHourMin
          } = res;
          const tax = 1.16;
          if (bikeHourMin && bikeHourMax) {
            this.vehicleRates.Motorcycle = {
              min: currency(bikeHourMin).multiply(tax),
              max: currency(bikeHourMax).multiply(tax)
            };
          } else {
            this.vehicleRates.Motorcycle = null;
          }

          if (carHourMin && carHourMax) {
            this.vehicleRates.Car = {
              min: currency(carHourMin).multiply(tax),
              max: currency(carHourMax).multiply(tax)
            };
          } else {
            this.vehicleRates.Car = null;
          }

          this.vehicleRates.Eco = null;
        })
        .catch(error => {
          if (error.statusCode === 404) {
            this.setDefaultValues();
          } else {
            this.$captureError(error);
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
#hour-rent-request-panel {
  position: absolute;
  width: 412px;
  margin-left: 5px;
  border-radius: 2px;
  background-color: $white;
  box-shadow: 0 3px 6px 0 rgba($black, 0.23),
    0 4px 6px 1px rgba($blue-bayoux, 0.28);
  z-index: 1000;

  .drivers-container {
    span {
      font-weight: 600;
    }
  }

  ::v-deep .favorite-addresses > .addresses {
    width: 324px;
    left: 50%;
    margin-left: -162px;
  }
}

.helmet-icon {
  height: 18px;
}

.drivers-input {
  width: 120px !important;
}

.date-picker-wrapper {
  min-width: 120px;
}
</style>
