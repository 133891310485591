<template>
  <div class="service-multi-point-destination">
    <collapsable-card
      :visible="visible"
      :title="title"
      :subtitle="subtitle"
      @update:visible="$emit('update:visible', $event)"
    >
      <template v-if="order > 1" v-slot:append-left>
        <z-button
          id="delete-btn"
          variant="secondary"
          class="mr-3"
          @click="$emit('remove')"
        >
          <font-awesome-icon icon="times" />
        </z-button>
      </template>
      <div class="pt-3">
        <b-row v-if="isOrigin" id="user-select" class="form-group">
          <b-col>
            <label for="client">¿Quién pidió el servicio?</label>
            <div class="select-wrapper">
              <z-dropdown-clients
                v-model="form.user"
                placeholder="Buscar cliente o sucursal"
                variant="white"
                search
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Nombre" label-for="input-name">
              <b-form-input
                :id="`input-name-${order}`"
                v-model="form.name"
                v-validate="'required'"
                data-vv-as="nombre"
                name="name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Teléfono" label-for="input-phone">
              <b-form-input
                :id="`input-phone-${order}`"
                v-model="form.phone"
                v-validate="'required'"
                data-vv-as="teléfono"
                type="number"
                name="phone"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Dirección" class="auto-complete">
              <div class="d-flex align-items-center w-100">
                <address-autocomplete
                  class="form-control"
                  :address="form.address"
                  placeholder="Calle, no. ext. no. int., colonia"
                  select-first-on-enter
                  name="address"
                  type="text"
                  required
                  @address-change="handleAddressChange"
                  @focus.native="showFavoriteAddresses = false"
                />
                <favorite-addresses
                  :show.sync="showFavoriteAddresses"
                  :disabled="!hasFavoriteAddresses"
                  :addresses="userFavoriteAddresses"
                  @address-selected="selectFavoriteAddress"
                />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Detalles" label-for="input-info">
              <b-form-input
                :id="`input-info-${order}`"
                v-model="form.information"
                v-validate=""
                data-vv-as="información"
                name="info"
                placeholder="Información adicional del origen"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col class="d-flex flex-column flex-sm-row align-items-sm-center">
            <div>
              <z-button
                id="reset-btn"
                variant="secondary"
                class="mr-3"
                @click="handleClean"
              >
                Limpiar
              </z-button>
              <z-button
                id="save-btn"
                :disabled="disableSaveButton || isFormPristine"
                @click="handleSave"
              >
                Continuar
              </z-button>
            </div>
            <span
              v-show="!isFormPristine && !disableSaveButton"
              class="ml-2  mt-2 mt-sm-0 text-secondary small"
            >
              Hay cambios sin guardar
            </span>
          </b-col>
        </b-row>
      </div>
    </collapsable-card>
  </div>
</template>

<script>
import AddressAutocomplete from "@/app/components/AddressAutocomplete";
import CollapsableCard from "@zubut/common/src/components/CollapsableCard";
import ZDropdownClients from "@/app/components/ZDropdownClients";
import FavoriteAddresses from "@/app/components/FavoriteAddresses";
import validateStateMixin from "@/mixins/validateState";

export default {
  name: "ServiceMultiPointDestination",

  components: {
    AddressAutocomplete,
    CollapsableCard,
    FavoriteAddresses,
    ZDropdownClients
  },

  mixins: [validateStateMixin],

  props: {
    order: {
      type: Number,
      required: true
    },
    isOrigin: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ""
    },
    favoriteAddresses: {
      type: Array,
      default: () => []
    },
    subtitle: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: "Entregar a"
    },
    users: {
      type: Array,
      default: () => []
    },
    visible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      form: {
        user: null,
        phone: "",
        name: "",
        address: {
          address: "",
          pristine: true
        },
        information: ""
      },
      showFavoriteAddresses: false
    };
  },

  computed: {
    disableSaveButton() {
      return (
        !this.isFormValid || !this.form.address.address || !this.isUserSelected
      );
    },
    userFavoriteAddresses() {
      return this.favoriteAddresses || [];
    },
    hasFavoriteAddresses() {
      return this.userFavoriteAddresses.length > 0;
    },
    isUserSelected() {
      /* Only origin destination should select user */
      if (this.isOrigin) {
        return this.form.user ? true : false;
      }
      return true;
    }
  },

  watch: {
    "form.user": {
      handler(newValue, oldValue) {
        if (oldValue !== newValue) {
          this.$emit("client-change", newValue);
          this.changeFieldsFlags({
            pristine: false,
            valid: true
          });
        }
      }
    }
  },

  methods: {
    handleSave() {
      this.$emit("update", this.form);
      this.$validator.reset("*");
      this.form.address.pristine = true;
      this.changeFieldsFlags({
        pristine: true
      });
    },
    handleClean() {
      this.showFavoriteAddresses = false;
      this.resetForm();
      this.$emit("clean", this.form);
    },
    handleAddressChange(address) {
      this.form.address = Object.assign({}, address);
      this.form.address.pristine = false;
      this.changeFieldsFlags({
        pristine: false
      });
    },
    selectFavoriteAddress(address) {
      this.form.name = address.name;
      this.form.phone = address.phone;
      this.form.information = address.information;
      this.form.address = {
        address: address.address,
        position: address.position,
        pristine: false
      };
      this.changeFieldsFlags({
        pristine: false
      });
    },
    validate() {
      this.$validator.reset("*");
    },
    changeFieldsFlags(flags) {
      Object.keys(this.veeFields).forEach(field => {
        this.$validator.flag(field, flags);
      });
    },
    resetForm() {
      this.form = {
        user: null,
        phone: "",
        name: "",
        address: {
          address: "",
          pristine: true
        },
        information: ""
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.select-wrapper {
  position: relative;
}

.icons {
  position: absolute;
  z-index: 1;
  margin: 8px 12px;
  width: 18px;
  height: 18px;
  bottom: 0px;
}

#delete-btn {
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 0px;

  &:focus {
    outline: none;
  }
}

.service-multi-point-destination {
  ::v-deep .form-group.auto-complete {
    > div {
      display: flex !important;
    }
  }
}
</style>
