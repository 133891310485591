<template>
  <div class="service-multi-point-resume border p-4 rounded mt-3">
    <div class="text-secondary small">Detalle del viaje</div>
    <div class="d-flex justify-content-between pt-2 mt-3">
      <div class="font-weight-bold">Total estimado</div>
      <div class="font-weight-bold cost-val">$ {{ cost }} mxn</div>
    </div>
    <div class="small text-secondary">
      <div class="d-flex justify-content-between">
        <div>Distancia</div>
        <div class="distance-val">{{ kms }} kms</div>
      </div>
      <div class="d-flex justify-content-between">
        <div>Tiempo</div>
        <div class="time-val">{{ minutes }} min</div>
      </div>
    </div>
    <hr class="my-2" />
    <div class="d-flex justify-content-between py-2">
      <div class="d-flex">
        <div class="p-2 rounded bg-light mr-2">
          <font-awesome-icon icon="helmet" flip="horizontal" size="2x" />
        </div>
        <div>
          <div class="small">Mensajero</div>
          <div class="text-secondary small">
            {{ !driver ? "Por determinar" : driver.name }}
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div class="mr-2">
          <font-awesome-icon
            v-show="vehicle === vehicleTypes.NUM_MOTORCYCLE"
            icon="zubut-motorcycle"
            size="lg"
          />
          <font-awesome-icon
            v-show="vehicle === vehicleTypes.NUM_ECO"
            icon="zubut-bike"
            size="lg"
          />
          <font-awesome-icon
            v-show="vehicle === vehicleTypes.NUM_CAR"
            icon="zubut-car"
            size="lg"
          />
        </div>
        <div class="text-secondary small">
          {{ vehicle === -1 ? "Por determinar" : vehicleName }}
        </div>
      </div>
    </div>
    <hr class="my-2" />
    <div class="mt-3">
      <z-map
        class="map"
        :destinations="destinations"
        :mark-origin="true"
        @rate-change="rateChange"
      />
    </div>
    <div>
      <div
        v-for="(destination, index) in formattedDestinations"
        :key="`destination-${index}`"
        class="d-flex mt-2"
      >
        <div class="mr-3" :class="{ 'text-primary': index !== 0 }">
          <font-awesome-icon icon="hexagon-bullet" size="lg" />
        </div>
        <div class="pt-2 small">
          <div class="font-weight-bold">{{ destination.name }}</div>
          <div class="mt-2">{{ destination.address.address }}</div>
          <div>T: {{ destination.phone }}</div>
        </div>
      </div>
    </div>
    <div>
      <div
        v-if="!formattedDestinations[0] || !formattedDestinations[0].name"
        class="d-flex mt-2"
      >
        <div class="mr-3">
          <font-awesome-icon icon="hexagon-bullet" size="lg" />
        </div>
        <div class="pt-2 small">
          <div class="font-weight-bold">Origen</div>
          <div class="mt-2 text-secondary">Sin dirección</div>
        </div>
      </div>
      <div
        v-if="!formattedDestinations[1] || !formattedDestinations[1].name"
        class="d-flex mt-2"
      >
        <div class="mr-3 text-primary">
          <font-awesome-icon icon="hexagon-bullet" size="lg" />
        </div>
        <div class="pt-2 small">
          <div class="font-weight-bold">Destino 1</div>
          <div class="mt-2 text-secondary">Sin dirección</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VehicleTypes from "@/constants/vehicles/type";
import ZMap from "@zubut/common/src/components/ZMap";

export default {
  name: "ServiceMultiPointResume",

  components: {
    ZMap
  },

  props: {
    cost: {
      type: Number,
      default: 0
    },
    destinations: {
      type: Array,
      default: () => []
    },
    driver: {
      type: Object,
      default: null
    },
    minutes: {
      type: [Number, String],
      default: 0
    },
    kms: {
      type: [Number, String],
      default: 0
    },
    vehicle: {
      type: Number,
      default: -1
    }
  },

  data() {
    return {
      vehicleTypes: VehicleTypes
    };
  },

  computed: {
    formattedDestinations() {
      return this.destinations.map(el => {
        el.position = el.address.position;
        return el;
      });
    },
    vehicleName() {
      return VehicleTypes.get[this.vehicle];
    }
  },

  methods: {
    rateChange({ km, minutes }) {
      this.$emit("rate-change", { km, minutes });
    }
  }
};
</script>

<style lang="scss">
.service-multi-point-resume {
  .map {
    height: 300px;
  }
}
</style>
