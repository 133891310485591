<template>
  <div>
    <b-card class="service-creation">
      <service-creation-header />
      <service-delivered-creation
        v-if="selectedServiceType === 'entregados'"
        @create="closeSection"
        @cancel="closeSection"
      />
      <service-multi-point-creation
        v-else-if="selectedServiceType === 'multi-punto'"
        @create="closeSection"
        @cancel="closeSection"
      />
      <service-rent-creation
        v-else-if="selectedServiceType === 'renta-hora'"
        @create="closeSection"
        @cancel="closeSection"
      />
    </b-card>
  </div>
</template>

<script>
import ServiceCreationHeader from "./ServiceCreationHeader";
import ServiceDeliveredCreation from "./ServiceDeliveredCreation";
import ServiceMultiPointCreation from "./ServiceMultiPointCreation/ServiceMultiPointCreation";
import ServiceRentCreation from "./ServiceRentCreation/ServiceRentCreation";

const validServiceTypes = new Set(["entregados", "multi-punto", "renta-hora"]);

export default {
  name: "ServiceCreation",

  components: {
    ServiceCreationHeader,
    ServiceDeliveredCreation,
    ServiceMultiPointCreation,
    ServiceRentCreation
  },

  data() {
    return {
      previousRoute: null
    };
  },

  computed: {
    selectedServiceType() {
      const type = this.$route.query && this.$route.query.serviceType;
      const isTypeValid =
        type != null && validServiceTypes.has(this.$route.query.serviceType);
      return isTypeValid ? type : "entregados";
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.previousRoute = from.name;
    });
  },

  methods: {
    closeSection() {
      if (this.previousRoute === "services") {
        this.$router.push({ name: "services" });
      } else {
        this.$router.push({ name: "serviceCenter" });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.service-creation {
  border-radius: 10px;
}
</style>
