<template>
  <gmap-autocomplete
    class="address-autocomplete"
    :value="addressText"
    :select-first-on-enter="selectFirstOnEnter"
    :placeholder="placeholder"
    :options="options"
    @place_changed="setPlace"
  >
  </gmap-autocomplete>
</template>

<script>
export default {
  name: "AddressAutocomplete",

  props: {
    value: {
      type: String,
      default: ""
    },
    address: {
      type: Object,
      required: false,
      default: null
    },
    options: {
      type: Object,
      default: () => ({
        componentRestrictions: { country: ["mx", "co"] },
        strictBounds: true
      })
    },
    placeholder: {
      type: String,
      default: ""
    },
    selectFirstOnEnter: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    addressText() {
      return this.address ? this.address.address : this.value;
    }
  },

  methods: {
    setPlace(place) {
      if (place.geometry) {
        const newAddress = {
          address: place.formatted_address,
          position: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          },
          valid: true
        };
        this.$emit("address-change", newAddress);
      } else {
        const newAddress = {
          address: place.name,
          position: null,
          valid: false
        };
        this.$emit("address-change", newAddress);
      }
    }
  }
};
</script>

<style lang="scss">
.pac-container {
  z-index: 2000 !important;
}
</style>
