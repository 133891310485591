const WEEK_FROM = 9;
const WEEK_TO = 23;

const SUNDAY_FROM = 10;
const SUNDAY_TO = 22;

const MIN_HOURS = 4;
const MAX_HOURS = 12;

const MIN_MINUTES = MIN_HOURS * 60;
const MAX_MINUTES = MAX_HOURS * 60;

export default {
  week: {
    from: WEEK_FROM,
    to: WEEK_TO
  },
  sunday: {
    from: SUNDAY_FROM,
    to: SUNDAY_TO
  },
  MIN_HOURS,
  WEEK_FROM,
  WEEK_TO,
  SUNDAY_FROM,
  SUNDAY_TO,
  MAX_HOURS,
  MAX_MINUTES,
  MIN_MINUTES
};
