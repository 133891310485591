<template>
  <div class="favorite-addresses">
    <button
      type="button"
      :disabled="disabled"
      class="text-secondary"
      :class="{ disabled: disabled }"
      @click="toggleShow"
    >
      <font-awesome-icon
        :class="{ disabled: disabled }"
        icon="favorite-address"
        size="xs"
      />
    </button>
    <b-list-group v-show="show" class="addresses">
      <z-search-input
        v-model="searchText"
        placeholder="Buscar dirección favorita"
      />
      <b-list-group-item
        v-for="address in filteredFavoriteAddresses"
        :key="address.id"
        @click="selectFavoriteAddress(address)"
      >
        {{ address[filterField] }}
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import ZSearchInput from "@zubut/common/src/components/ZSearchInput";
import _toLower from "lodash/toLower";

export default {
  name: "FavoriteAddresses",

  components: {
    ZSearchInput
  },

  props: {
    addresses: {
      type: Array,
      default: () => []
    },
    filterField: {
      type: String,
      default: "name"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      searchText: ""
    };
  },

  computed: {
    filteredFavoriteAddresses() {
      return this.addresses.filter(obj => {
        return _toLower(obj[this.filterField]).includes(
          _toLower(this.searchText)
        );
      });
    }
  },

  methods: {
    selectFavoriteAddress(address) {
      this.$emit("address-selected", address);
      this.$emit("update:show", false);
    },
    toggleShow() {
      this.$emit("update:show", !this.show);
    }
  }
};
</script>

<style lang="scss" scoped>
.favorite-addresses {
  width: 30px;

  button {
    width: inherit;
    height: 100%;
    border: none;
    padding: 0.2rem 0.2rem 0.6rem 0.2rem;
    background-color: transparent !important;
    outline: none;

    &:hover {
      cursor: pointer;
    }
  }

  .disabled {
    color: $very-light-grey;

    &:hover {
      cursor: default;
    }
  }
}

.addresses {
  position: absolute;
  left: 0px;
  width: 100%;
  background-color: $white;
  box-shadow: 0 3px 6px 0 rgba($black, 0.23), 0 3px 6px 0 rgba($black, 0.16);
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px;
  border-radius: 2px;
  z-index: 1;

  #search {
    margin: 0 !important;
    width: 100% !important;
    border: none;
  }

  .list-group-item:hover {
    background-color: $white-smoke;
  }
}

.magnifier-icon {
  fill: $nobel;
  height: 1em;
  flex: 1;
}

input {
  width: 100%;
  margin-left: 0.5em;
  margin-right: 0.5em;
  border: none;
  padding: 1em;
}

input:focus {
  outline: none;
  box-shadow: none !important;
}
</style>
