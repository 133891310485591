<template>
  <div class="service-multi-point-driver">
    <collapsable-card
      :visible="visible"
      title="Asignar mensajero"
      :subtitle="subtitle"
      @update:visible="$emit('update:visible', $event)"
    >
      <div class="pt-3">
        <b-row id="driver-select" class="form-group">
          <b-col>
            <label for="driver">Mensajero</label>
            <div class="select-wrapper">
              <z-dropdown-drivers
                :value="selectedDriver"
                placeholder="Seleccione un mensajero"
                search
                variant="white"
                @change="handleDriverChange"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <quick-message
              class="mb-3"
              with-icon
              show
              message="El precio por km puede variar según el usuario seleccionado."
            />
            <label for="vehicle-type">Vehículo</label>
            <delivery-methods
              id="vehicle-type"
              class="mt-n1"
              :prices="vehicleRates"
              :vehicle-type.sync="selectedVehicle"
              :car-allowed="isCarAllowed"
              :eco-allowed="isEcoAllowed"
              :motorcycle-allowed="isMotorcycleAllowed"
              not-allowed-message="Tarifa no establecida"
            />
          </b-col>
        </b-row>
      </div>
    </collapsable-card>
  </div>
</template>

<script>
import ZDropdownDrivers from "@/app/components/ZDropdownDrivers";
import CollapsableCard from "@zubut/common/src/components/CollapsableCard";
import DeliveryMethods from "@zubut/common/src/components/DeliveryMethods";
import VehicleTypes from "@/constants/vehicles/type";

export default {
  name: "ServiceMultiPointDriver",

  components: {
    CollapsableCard,
    DeliveryMethods,
    ZDropdownDrivers
  },

  props: {
    driver: {
      type: Object,
      default: null
    },
    subtitle: {
      type: String,
      default: ""
    },
    vehicle: {
      type: Number,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    },
    vehicleRates: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      selectedDriver: null,
      selectedVehicle: VehicleTypes.NUM_MOTORCYCLE
    };
  },

  computed: {
    isCarAllowed() {
      return this.vehicleRates.Car !== null;
    },
    isMotorcycleAllowed() {
      return this.vehicleRates.Motorcycle !== null;
    },
    isEcoAllowed() {
      return this.vehicleRates.Eco !== null;
    }
  },

  watch: {
    driver(newVal) {
      this.selectedDriver = newVal.id;
    },
    selectedVehicle(newVal) {
      this.$emit("update:vehicle", newVal);
    },
    vehicleType(newVal) {
      this.selectedVehicle = newVal;
    }
  },

  methods: {
    handleDriverChange(driver) {
      this.selectedDriver = driver.value;
      this.$emit("update:driver", { id: driver.value, name: driver.text });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/bootstrap/alert.scss";

.select-wrapper {
  position: relative;
}

.icons {
  position: absolute;
  z-index: 1;
  margin: 8px 12px;
  width: 18px;
  height: 18px;
  bottom: 0px;
}
</style>
