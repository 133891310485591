<template>
  <div class="service-delivered">
    <b-row>
      <b-col lg="6">
        <div class="form-section">
          <h2>Servicio realizado</h2>
          <b-row class="form-group">
            <b-col md="9">
              <label for="client">¿Quién pidió el servicio?</label>
              <div class="select-wrapper">
                <z-dropdown
                  id="client"
                  v-model="selectedClient"
                  :options="clients"
                  variant="white"
                  :show-header="false"
                  placeholder="Ingresa nombre, cliente o sucursal"
                  search
                />
              </div>
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col md="9">
              <label for="driver">Mensajeros</label>
              <div class="select-wrapper">
                <z-dropdown-drivers
                  v-model="selectedDriver"
                  placeholder="Ingresa el nombre del mensajero"
                  search
                  :show-header="false"
                  variant="white"
                  :status="driverStatus.NUM_ACTIVE"
                />
              </div>
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col md="9">
              <label for="packages-assigned">Paquetes asignados</label>
              <b-input
                id="packages-assigned"
                v-model="packagesAssigned"
                type="number"
                placeholder="0"
              />
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col md="9">
              <label for="packages">Paquetes entregados</label>
              <b-input
                id="packages"
                v-model="packagesDelivered"
                type="number"
                placeholder="0"
              />
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col md="9">
              <label for="date">Fecha</label>
              <div class="select-wrapper">
                <date-icon class="icons" />
                <date-picker
                  id="date"
                  v-model="date"
                  :language="dateLanguage"
                  format="dd / MMM / yyyy"
                  calendar-class="date-picker"
                  placeholder="Selecciona una fecha"
                  input-class="form-control bg-white"
                  :disabled-dates="disabledDates"
                />
              </div>
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col md="9">
              <label for="city">Ciudad</label>
              <div class="select-wrapper">
                <z-dropdown
                  id="client"
                  v-model="selectedCity"
                  :options="cities"
                  variant="white"
                  :show-header="false"
                  placeholder="Seleccionar ciudad"
                  search
                />
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="9">
              <label for="vehicle">Vehículo</label>
              <delivery-methods
                class="mt-n1"
                :prices="vehicleRates"
                :vehicle-type.sync="selectedVehicle"
                :car-allowed="isCarAllowed"
                :eco-allowed="isEcoAllowed"
                :motorcycle-allowed="isMotorcycleAllowed"
                not-allowed-message="Tarifa no establecida"
              />
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="action-buttons">
        <z-button
          data-test-id="cancel-btn"
          variant="secondary"
          @click="$emit('cancel')"
        >
          Cancelar
        </z-button>
        <z-button
          data-test-id="create-btn"
          :disabled="!validFields"
          @click="createService"
        >
          Ingresar servicio(s)
        </z-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import DeliveryMethods from "@zubut/common/src/components/DeliveryMethods";
import ZDropdownDrivers from "@/app/components/ZDropdownDrivers";
import UserStatus from "@/constants/clients/status";
import DriverStatus from "@/constants/drivers/status";
import VehicleTypes from "@/constants/vehicles/type";
import Services from "@/services/services";
import Clients from "@/services/clients";
import Cities from "@/services/cities";
import DateIcon from "@/assets/icons/ic_date.svg";
import notify from "@/mixins/notify";
import DatePicker from "vuejs-datepicker";
import { es } from "vuejs-datepicker/dist/locale";
import subDays from "date-fns/subDays";
import startOfDay from "date-fns/startOfDay";

export default {
  name: "",

  components: {
    DateIcon,
    DatePicker,
    DeliveryMethods,
    ZDropdown,
    ZDropdownDrivers
  },

  mixins: [notify("notifications")],

  data() {
    return {
      users: [],
      drivers: [],
      cities: [],
      dateLanguage: es,
      packagesAssigned: null,
      packagesDelivered: null,
      selectedClient: null,
      selectedDriver: null,
      date: startOfDay(subDays(new Date(), 1)),
      disabledDates: {
        from: startOfDay(new Date())
      },
      selectedCity: null,
      selectedVehicle: VehicleTypes.NUM_MOTORCYCLE,
      vehicleRates: {
        Motorcycle: null,
        Eco: null,
        Car: null
      },
      driverStatus: DriverStatus
    };
  },

  computed: {
    clients() {
      return this.users.map(user => ({
        value: user.id,
        text: user.name
      }));
    },
    selectedPaymentMethod() {
      const selectedUser = this.users.find(
        user => user.id === this.selectedClient
      );
      if (selectedUser.walletId)
        return {
          method: "walletId",
          id: selectedUser.walletId
        };
      else if (selectedUser.cardId) {
        return {
          method: "cardId",
          id: selectedUser.cardId
        };
      }
      return {};
    },
    validFields() {
      if (!this.selectedCity || this.selectedCity == null) return false;
      if (!this.selectedClient || this.selectedClient == null) return false;
      if (!this.selectedDriver || this.selectedDriver == null) return false;
      if (
        !this.packagesAssigned ||
        isNaN(this.packagesAssigned) ||
        parseInt(this.packagesAssigned) <= 0
      ) {
        return false;
      }
      if (
        !this.packagesDelivered ||
        isNaN(this.packagesDelivered) ||
        parseInt(this.packagesDelivered) <= 0 ||
        parseInt(this.packagesDelivered) > parseInt(this.packagesAssigned)
      ) {
        return false;
      }
      if (!this.date || isNaN(this.date.getTime())) return false;
      return true;
    },
    isCarAllowed() {
      return this.vehicleRates.Car !== null;
    },
    isMotorcycleAllowed() {
      return this.vehicleRates.Motorcycle !== null;
    },
    isEcoAllowed() {
      return this.vehicleRates.Eco !== null;
    }
  },

  watch: {
    selectedClient() {
      this.getRatesPackages();
    }
  },

  beforeMount() {
    this.fetchAllUsers();
    this.fetchAllCities();
  },

  methods: {
    getRatesPackages() {
      Clients.getRatesPackages(this.selectedClient)
        .then(res => {
          const tax = 1.16;
          if (res.bikeDelivery && res.bikeAttempt) {
            this.vehicleRates.Motorcycle = {
              delivery: res.bikeDelivery * tax,
              attempt: res.bikeAttempt * tax
            };
          } else {
            this.vehicleRates.Motorcycle = null;
          }

          if (res.carDelivery && res.carAttempt) {
            this.vehicleRates.Car = {
              delivery: res.carDelivery * tax,
              attempt: res.carAttempt * tax
            };
          } else {
            this.vehicleRates.Car = null;
          }

          this.vehicleRates.Eco = null;
        })
        .catch(error => {
          if (error.statusCode === 404) {
            this.setDefaultValues();
          } else {
            this.$captureError(error);
          }
        });
    },
    fetchAllUsers() {
      Clients.find({
        filter: {
          where: {
            and: [{ status: UserStatus.NUM_ACTIVE }]
          },
          include: ["wallet", "creditCards"]
        }
      })
        .then(users => {
          this.users = users.map(user => {
            const defaultCreditCard =
              user.creditCards && user.creditCards.find(card => card.default);
            return {
              id: user.id,
              name: user.name,
              walletId: user.wallet && user.wallet.id,
              cardId: defaultCreditCard && defaultCreditCard.id
            };
          });
        })
        .catch(error => {
          this.$captureError(error);
        });
    },
    fetchAllCities() {
      Cities.find({}).then(cities => {
        this.cities = cities.map(city => ({
          value: city.id,
          text: city.name
        }));
        this.selectedCity =
          this.cities.find(
            city => city.text === "Zona Metropolitana de Guadalajara"
          )?.value || null;
      });
    },
    createService() {
      const data = {
        clientId: this.selectedClient,
        driversId: this.selectedDriver,
        cityId: this.selectedCity,
        createdAt: this.date,
        additional: {
          attempts:
            parseInt(this.packagesAssigned) - parseInt(this.packagesDelivered),
          deliveries: parseInt(this.packagesDelivered)
        },
        vehicleType: this.selectedVehicle
      };
      const { method, id } = this.selectedPaymentMethod;
      if (method) {
        data[method] = id;
      }
      Services.packageService(data)
        .then(() => {
          this.notify({
            text: "El servicio fue creado con éxito",
            title: "Operación exitosa",
            type: "success"
          });
          this.$emit("create");
        })
        .catch(err => {
          this.$captureError(err);
          this.notify({
            text: err.message,
            title: "Error al crear servicio",
            type: "error"
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.service-delivered {
  .form-section {
    margin-top: 1rem;
    border: 1px solid $gainsboro;
    border-radius: 8px;
    padding: 24px;

    h2 {
      font-size: 14px;
      line-height: 18px;
      color: $nero;
      margin-bottom: 1.5rem;
    }

    .select-wrapper {
      position: relative;
    }

    .icons {
      position: absolute;
      z-index: 1;
      margin: 8px 12px;
      width: 18px;
      height: 18px;
    }

    .form-group {
      margin-bottom: 1rem;
    }

    .form-group label {
      font-size: 12px;
      color: $nero;
    }
  }
  .action-buttons {
    margin-top: 2rem;

    button {
      margin-right: 1rem;
      height: 40px;
    }
  }
}
</style>

<style lang="scss">
.service-delivered {
  #date {
    background-color: $white;
    border: solid 1px $gainsboro;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 44px;
  }
}
</style>
