<template>
  <div class="service-multi-point-additional">
    <collapsable-card
      :visible="visible"
      title="Información adicional (Opcional)"
      @update:visible="$emit('update:visible', $event)"
    >
      <div class="pt-3">
        <b-form-checkbox-group
          :key="checkboxesKey"
          v-model="selected"
          :options="options"
          stacked
        ></b-form-checkbox-group>
      </div>
      <b-row class="mt-5">
        <b-col class="d-flex flex-column flex-sm-row align-items-sm-center">
          <div>
            <z-button
              id="reset-btn"
              variant="secondary"
              class="mr-3"
              @click="handleClean"
            >
              Limpiar
            </z-button>
            <z-button
              id="save-btn"
              :disabled="disableSaveButton"
              @click="handleSave"
            >
              Continuar
            </z-button>
          </div>
          <span
            v-show="!disableSaveButton"
            class="ml-2 mt-2 mt-sm-0 text-secondary small"
          >
            Hay cambios sin guardar
          </span>
        </b-col>
      </b-row>
    </collapsable-card>
  </div>
</template>

<script>
import _isEqual from "lodash/isEqual";
import CollapsableCard from "@zubut/common/src/components/CollapsableCard";

export default {
  name: "ServiceMultiPointAdditional",

  components: {
    CollapsableCard
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    additional: {
      type: Object,
      require: true,
      default: null
    }
  },

  data() {
    return {
      selected: [],
      draftSelected: [],
      options: [
        { text: "Firma en el destino", value: "signature" },
        {
          text: "Artículos restringidos (+18)",
          value: "restrictedItems"
        }
      ],
      checkboxesKey: 0
    };
  },

  computed: {
    disableSaveButton() {
      return _isEqual(this.selected, this.draftSelected);
    }
  },

  watch: {
    selected(newVal) {
      if (newVal.includes("restrictedItems") && !newVal.includes("signature")) {
        this.selected = [...this.selected, "signature"];
        this.checkboxesKey++;
      }
    },
    additional(newVal) {
      this.draftSelected = [];
      for (let value of Object.keys(this.additional)) {
        if (this.additional[value]) {
          this.draftSelected.push(value);
        }
      }
    }
  },

  methods: {
    handleClean() {
      this.selected = [];
    },
    handleSave() {
      let additional = {};
      this.selected.map(key => {
        additional[key] = true;
      });
      this.$emit("update:additional", additional);
      this.$emit("update:visible", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.service-multi-point-additional {
  ::v-deep .custom-control {
    margin-top: 1rem;
  }
}
</style>
