<template>
  <div class="service-multi-point-creation">
    <quick-message
      class="mb-3"
      with-icon
      :show="hasError"
      type="error"
      :message="errorMessage"
    />
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col
            v-for="(destination, index) in destinations"
            :key="`destination-${index}`"
            cols="12"
            class="mt-3"
          >
            <service-multi-point-destination
              :order="index"
              :is-origin="index === 0"
              :title="index === 0 ? 'Recoger en' : 'Entregar a'"
              :subtitle="destination.name"
              :name="destination.name"
              :phone="destination.phone"
              :info="destination.info"
              :address="destination.address"
              :favorite-addresses="favoriteAddresses"
              :visible.sync="destination.visible"
              @update="handleUpdate($event, index)"
              @clean="handleUpdate($event, index, true)"
              @remove="handleRemove(index)"
              @client-change="fetchClientData"
            />
          </b-col>
          <b-col
            cols="12"
            class="text-right"
            :class="{ 'mt-3': !emptyDestinations }"
          >
            <b-button
              v-show="!emptyDestinations"
              variant="outline-primary"
              @click="addDestination"
            >
              <div class="small text-decoration-none">
                <font-awesome-icon icon="plus-circle" class="mr-1" />
                Añadir otro destino
              </div>
            </b-button>
          </b-col>
          <b-col cols="12" class="mt-3">
            <service-multi-point-additional
              :visible.sync="showAdditional"
              :additional.sync="additional"
            />
          </b-col>
          <b-col cols="12" class="mt-3">
            <service-multi-point-driver
              :subtitle="driverFormSubtitle"
              :drivers="drivers"
              :driver.sync="selectedDriver"
              :vehicle.sync="selectedVehicle"
              :visible.sync="showDriverCard"
              :vehicle-rates="vehicleRates"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <service-multi-point-resume
          class="service-multi-point-resume"
          :destinations="destinations.filter(el => el.address.address)"
          :driver="selectedDriver"
          :vehicle="selectedVehicle"
          :cost="rateEstimate.taxedRate"
          :kms="rateEstimate.kms"
          :minutes="rateEstimate.minutes"
          @rate-change="rateChange"
        />
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <z-button class="mr-3" variant="secondary" @click="$emit('cancel')">
          Cancelar
        </z-button>
        <z-button :disabled="disableSaveButton" @click="createService">
          Crear servicio
        </z-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ServiceMultiPointDestination from "./ServiceMultiPointDestination";
import ServiceMultiPointDriver from "./ServiceMultiPointDriver";
import ServiceMultiPointAdditional from "./ServiceMultiPointAdditional";
import ServiceMultiPointResume from "./ServiceMultiPointResume";
import Drivers from "@/services/drivers";
import Services from "@/services/services";
import Clients from "@/services/clients";
import ServiceStatus from "@/constants/services/status";
import VehicleTypes from "@/constants/vehicles/type";
import notify from "@/mixins/notify";
import { formatSendPhone } from "@zubut/common/src/utils/strings";

export default {
  name: "ServiceMultiPointCreation",

  components: {
    ServiceMultiPointDestination,
    ServiceMultiPointDriver,
    ServiceMultiPointAdditional,
    ServiceMultiPointResume
  },

  mixins: [notify("notifications")],

  data() {
    return {
      destinations: [
        {
          name: "",
          phone: "",
          address: { address: "" },
          information: "",
          user: null,
          visible: true,
          order: 0
        },
        {
          name: "",
          phone: "",
          address: { address: "" },
          information: "",
          visible: false
        }
      ],
      additional: {
        signature: false,
        restrictedItems: false,
        scheduled: false,
        driverReturn: false
      },
      drivers: [],
      selectedDriver: null,
      selectedUser: null,
      selectedVehicle: VehicleTypes.NUM_MOTORCYCLE,
      favoriteAddresses: [],
      showDriverCard: false,
      showAdditional: false,
      hasError: false,
      errorMessage: "",
      rateEstimate: {
        kms: 0,
        cost: 0,
        minutes: 0,
        cityId: 0
      },
      mapRates: {
        minutes: 0,
        kms: 0
      },
      vehicleRates: {
        Motorcycle: null,
        Car: null,
        Eco: null
      },
      creatingService: false
    };
  },

  computed: {
    driverFormSubtitle() {
      if (this.selectedDriver) {
        return `${this.selectedDriver.name} (${
          VehicleTypes.get[this.selectedVehicle]
        })`;
      }
      return "";
    },
    emptyDestinations() {
      return this.destinations.some(el => !el.address.address);
    },
    disableSaveButton() {
      return this.creatingService || this.emptyDestinations || this.hasError;
    }
  },

  watch: {
    selectedVehicle() {
      this.serviceRate();
    }
  },

  beforeMount() {
    // this.fetchAllUsers();
    this.getDrivers();
  },

  methods: {
    fetchClientData(clientId) {
      if (clientId) {
        Clients.findById({
          id: clientId,
          filter: {
            include: ["wallet", "creditCards", "addresses"]
          }
        }).then(user => {
          this.favoriteAddresses = user.addresses;
          this.selectedUser = user;
        });
      }
    },
    getDrivers() {
      Drivers.active()
        .then(({ drivers }) => {
          this.drivers = drivers;
        })
        .catch(err => {
          this.$captureError(err);
        });
    },
    serviceRate() {
      this.hasError = false;
      if (this.selectedUser) {
        this.$store
          .dispatch("services/calculateServiceRate", {
            minutes: this.mapRates.minutes,
            kms: this.mapRates.kms,
            destinations: this.destinations,
            vehicleType: this.selectedVehicle,
            clientId: this.selectedUser.id
          })
          .then(response => {
            const { vehicleRates } = response;
            this.rateEstimate = response;
            this.vehicleRates.Motorcycle = vehicleRates.bikeKMTotal;
            this.vehicleRates.Car = vehicleRates.carKMTotal;
            this.vehicleRates.Eco = vehicleRates.ecoKMTotal;
          })
          .catch(err => {
            this.$captureError(err);
            this.hasError = true;
            this.errorMessage = err.message;
          });
      }
    },
    handleRemove(index) {
      this.destinations.splice(index, 1);
    },
    handleUpdate(data, index, clean = false) {
      if (this.destinations.length) {
        const visible = clean ? true : false;
        if (visible) {
          this.vehicleRates = {
            Motorcycle: null,
            Car: null,
            Eco: null
          };
          this.rateEstimate = {
            kms: 0,
            cost: 0,
            minutes: 0,
            cityId: 0
          };
        }
        this.updateDestination({ data, index, visible });
      }

      /* The destination form should matain open after cleaning it */
      if (!clean) {
        if (this.destinations[index + 1]) {
          this.destinations[index + 1].visible = true;
        } else {
          this.showDriverCard = true;
        }
      }
      this.serviceRate();
    },
    updateDestination({ data, index, visible }) {
      /* Destinations are updated like this to mantain reactivity */
      this.destinations[index].visible = visible;
      this.destinations[index].name = data.name;
      this.destinations[index].phone = formatSendPhone(data.phone);
      this.destinations[index].address = { ...data.address };
      this.destinations[index].information = data.information;
    },
    addDestination() {
      this.destinations.push({
        name: "",
        phone: "",
        address: {
          address: ""
        },
        information: "",
        visible: true
      });
    },
    createService() {
      this.creatingService = true;
      let serviceData = {
        destinations: this.destinations.map(item => ({
          ...item,
          ...item.address
        })),
        estimatedTime: this.rateEstimate.minutes,
        estimatedRate: this.rateEstimate.rate,
        km: this.rateEstimate.kms,
        paid: false,
        status:
          this.selectedDriver != null
            ? ServiceStatus.NUM_ACTIVE
            : ServiceStatus.NUM_CREATED,
        createdAt: new Date(),
        clientId: this.selectedUser.id,
        additional: this.additional,
        driversId: this.selectedDriver?.id || null,
        acceptedAt: new Date(),
        optimize: true,
        respectDestination: true,
        cityId: this.rateEstimate.cityId,
        vehicleType: this.selectedVehicle
      };

      // TODO: Block when the user has not zubut credit
      if (this.selectedUser.walletId) {
        serviceData.walletId = this.selectedUser.walletId;
      } else if (this.selectedUser.cardId) {
        serviceData.cardId = this.selectedUser.cardId;
      }

      Services.create({
        data: serviceData
      })
        .then(() => {
          this.notify({
            type: "success",
            title: "Servicio creado",
            text: "Servicio creado con éxito"
          });
        })
        .catch(err => {
          this.$captureError(err);
          this.notify({
            type: "error",
            title: "Error",
            text: "Occurió un error al intentar crear el servicio"
          });
        })
        .finally(() => {
          this.creatingService = false;
          this.$emit("create");
        });
    },

    rateChange({ minutes, km }) {
      if (this.mapRates.kms !== km) {
        this.mapRates.minutes = minutes;
        this.mapRates.kms = km;
        this.serviceRate();
      }
    }
  }
};
</script>
