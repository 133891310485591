<template>
  <b-row class="p-3 header">
    <b-col>
      <div class="d-flex flex-column flex-md-row justify-content-md-between">
        <div class="left-header">
          <servicios-entregados-icon
            v-if="serviceTypeSelected === 'entregados'"
            class="section-icon"
          />
          <servicios-por-hora-icon
            v-if="serviceTypeSelected === 'renta-hora'"
            class="section-icon"
          />
          <servicios-multi-punto-icon
            v-if="serviceTypeSelected === 'multi-punto'"
            class="section-icon"
          />
          <div>
            <h1 class="mt-3 mt-sm-0">Nuevo servicio {{ serviceTypeName }}</h1>
            <p>
              Cambia de tipo de servicio presionando el campo "tipo de servicio"
            </p>
          </div>
        </div>
        <div class="right-header">
          <p>Tipo de servicio</p>
          <z-dropdown
            v-model="serviceTypeSelected"
            class="service-selector"
            :options="serviceTypes"
          />
        </div>
      </div>
      <div class="mt-3 mb-3 divider" />
    </b-col>
  </b-row>
</template>

<script>
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ServiciosEntregadosIcon from "@/assets/img/entregados2.svg";
import ServiciosPorHoraIcon from "@/assets/img/servicios-renta-hora.svg";
import ServiciosMultiPuntoIcon from "@/assets/img/servicios-multi-punto.svg";

const validServiceTypes = new Set(["entregados", "multi-punto", "renta-hora"]);

export default {
  components: {
    ServiciosEntregadosIcon,
    ServiciosPorHoraIcon,
    ServiciosMultiPuntoIcon,
    ZDropdown
  },
  data() {
    return {
      serviceTypes: [
        { value: "entregados", text: "Entregados" },
        { value: "multi-punto", text: "Express" },
        { value: "renta-hora", text: "Renta por hora" }
      ]
    };
  },
  computed: {
    serviceTypeSelected: {
      get() {
        const type = this.$route.query && this.$route.query.serviceType;
        const isTypeValid =
          type != null && validServiceTypes.has(this.$route.query.serviceType);
        return isTypeValid
          ? this.serviceTypes.find(obj => obj.value === type).value
          : "entregados";
      },
      set(serviceType) {
        if (
          serviceType != null &&
          this.$route.query.serviceType !== serviceType
        ) {
          this.$router.push({
            name: "serviceCreation",
            query: { serviceType }
          });
        }
      }
    },
    serviceTypeName() {
      return this.serviceTypes.find(
        obj => obj.value === this.serviceTypeSelected
      ).text;
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  .left-header {
    h1 {
      font-size: 20px;
      line-height: 24px;
      color: $nero;
    }
    p {
      color: $dim-gray;
    }
    .section-icon {
      width: 42px;
      height: 42px;
      margin-top: 3px;
      vertical-align: top;
      background-color: $gainsboro;
      border-radius: 12px;
    }
    & > * {
      display: inline-block;
      margin-right: 2rem;
    }
    & > *:last-child {
      margin-right: 0;
    }
  }
  .right-header {
    p {
      color: $nero;
    }
    .service-selector {
      width: 250px;
    }
  }
  p {
    font-size: 12px;
    line-height: 16px;
  }
  .divider {
    height: 1px;
    background-color: $gainsboro;
  }
}
</style>
